import { Component, OnInit } from '@angular/core';
import { FeedService } from 'src/app/services/feed.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Feed } from 'src/app/model/feed';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/model/category';

@Component({
  selector: 'app-feed-gnews',
  templateUrl: './feed-gnews.component.html',
  styleUrls: ['./feed-gnews.component.scss']
})
export class FeedGnewsComponent implements OnInit {

  listGnews = []
  displayedColumns: string[] = ['select', 'date_publish', 'title', 'source', 'category'];
  selection = new SelectionModel<any>(true, []);

  categorys: Category[] = [];

  constructor(
    private feedService: FeedService,
    private categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.getFeedGnewsAll();
    this.getCategoryAll();
  }

  getFeedGnewsAll() {
    this.feedService.getGnews().subscribe(data => {
      console.log(data)
      this.listGnews = (data as any).articles;
    })
  }

  getCategoryAll() {
    this.categoryService.getCategorys().subscribe(data => {
      this.categorys = (data as any);
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listGnews.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.listGnews.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  setCategory(article, event) {
    let category = event.value;
    article['category'] = category;
  }

  enviar() {
    console.log(this.selection.selected)
    let feeds = [];
    this.selection.selected.forEach(article => {
      let feed: Feed = {
        title: article.title,
        resume: article.description,
        image_header: article.image,
        link_font: article.url,
        source: article.source,
        created_at: article.publishedAt,
        last_update: article.publishedAt,
        content: article.description,
        category: article.category
      }

      feeds.push(feed)

      this.feedService.saveFeed(feed)
    })
  }

}
