import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Streamer } from '../model/streamer';

@Injectable({
  providedIn: 'root'
})
export class StreamerService {

  private streamersCollection: AngularFirestoreCollection<Streamer> = this.afs.collection('streamers');

  constructor(private afs: AngularFirestore) { }

  getStreamers(): Observable<Streamer[]> {
    return this.streamersCollection.valueChanges();
  }

  save(streamer) {
    streamer.id = this.afs.createId();
    return this.streamersCollection.doc(streamer.id).set(streamer);
  }

  delete(streamer) {
    return this.streamersCollection.doc(streamer.id).delete();
  }

  update(streamer) {
    return this.streamersCollection.doc(streamer.id).update(streamer);
  }
}
