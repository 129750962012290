import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Guilda } from '../model/guilda';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuildaService {

  private guildasCollection: AngularFirestoreCollection<Guilda> = this.afs.collection('guildas');

  constructor(private afs: AngularFirestore) { }

  getGuildas(): Observable<Guilda[]> {
    return this.guildasCollection.valueChanges();
  }

  save(guilda) {
    guilda.id = this.afs.createId();
    return this.guildasCollection.doc(guilda.id).set(guilda);
  }

  delete(guilda) {
    return this.guildasCollection.doc(guilda.id).delete();
  }

  update(guilda) {
    return this.guildasCollection.doc(guilda.id).update(guilda);
  }
}
