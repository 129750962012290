import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Plataforma } from '../model/plataforma';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlataformaService {

  private plataformasCollection: AngularFirestoreCollection<Plataforma> = this.afs.collection('plataformas');

  constructor(private afs: AngularFirestore) { }

  getPlataformas(): Observable<Plataforma[]> {
    return this.plataformasCollection.valueChanges();
  }

  savePlataforma(plataforma) {
    plataforma.id = this.afs.createId();
    return this.plataformasCollection.doc(plataforma.id).set(plataforma);
  }

  deletePlataforma(plataforma) {
    return this.plataformasCollection.doc(plataforma.id).delete();
  }

  updatePlataforma(plataforma) {
    return this.plataformasCollection.doc(plataforma.id).update(plataforma);
  }
}
