import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Streamer, StreamerPlataforma } from 'src/app/model/streamer';
import { Guilda } from 'src/app/model/guilda';
import { FormGroup, Validators, NgForm, FormBuilder, FormControl } from '@angular/forms';
import { StreamerService } from 'src/app/services/streamer.service';
import { GuildaService } from 'src/app/services/guilda.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Plataforma } from 'src/app/model/plataforma';
import { PlataformaService } from 'src/app/services/plataforma.service';

@Component({
  selector: 'app-streamer',
  templateUrl: './streamer.component.html',
  styleUrls: ['./streamer.component.scss']
})
export class StreamerComponent implements OnInit {

  streamers$: Observable<Streamer[]>;
  guildas$: Observable<Guilda[]>;
  plataformas$: Observable<Plataforma[]>;

  streamerPlataformas: StreamerPlataforma[] = [];

  streamerForm: FormGroup = this.fb.group({
    id: [undefined],
    name: ['', [Validators.required, Validators.minLength(2)]],
    photo_avatar: ['', [Validators.required]],
    bio: [''],
    guilda: [undefined],
    last_update: [undefined],
    created_at: [undefined]
  })

  plataformaForm: FormGroup = this.fb.group({
    plataforma: [undefined, [Validators.required]],
    name_channel: ['', [Validators.required]],
    id_channel: ['', [Validators.required]]
  })

  @ViewChild('form') form: NgForm;
  @ViewChild('formPlat') formPlat: NgForm;

  constructor(
    private fb: FormBuilder,
    private streamerService: StreamerService,
    private guildaService: GuildaService,
    private plataformaService: PlataformaService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.streamers$ = this.streamerService.getStreamers();
    this.guildas$ = this.guildaService.getGuildas();
    this.plataformas$ = this.plataformaService.getPlataformas();
    
  }

  save() {
    let streamer: Streamer = this.streamerForm.value;
    streamer.last_update = new Date();
    streamer.plataformas = this.streamerPlataformas;
    if (!streamer.id) {
      streamer.created_at = new Date();

      this.streamerService.save(streamer).then(() => {
        this.snackBar.open('Streamer salvo com sucesso', 'OK', {duration: 2000});
        this.form.resetForm();
        this.streamerPlataformas = [];
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    } else {
      this.streamerService.update(streamer).then(() => {
        this.snackBar.open('Streamer atualizado com sucesso', 'OK', {duration: 2000});
        this.form.resetForm();
        this.streamerPlataformas = [];
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    }
  }

  addPlataforma(){
    let p = this.plataformaForm.value;
    console.log(this.plataformaForm.value)
    console.log(this.streamerForm.value)
    this.streamerPlataformas.push(p);
    this.formPlat.resetForm();
  }

  cancelPlataforma(){
    this.formPlat.resetForm();
  }

  cancel() {
    this.streamerForm.reset();
  }

  edit(streamer: Streamer) { 
    if(this.streamerForm.controls["plataformas"])
      this.streamerForm.removeControl("plataformas");
    if(streamer.plataformas)
      this.streamerForm.addControl("plataformas", new FormControl())
    this.streamerForm.setValue(streamer);
    // if(streamer.plataformas)
    this.streamerPlataformas = streamer.plataformas ? streamer.plataformas : [];
  }

  delete(streamer: Streamer) {
    this.streamerService.delete(streamer).then(() => {
      this.snackBar.open('Streamer removido com sucesso', 'OK', {duration: 2000});
    }).catch(() => {
      this.snackBar.open('Erro ao remover, tente novamente mais tarde', 'OK', {duration: 3000});
    })
  }

}
