<mat-card fxFlex>
    <mat-card-title>
        Streamer
    </mat-card-title>
    
    <mat-card-content>
        <form [formGroup]="streamerForm" (ngSubmit)="save()"  #form="ngForm">
            <div fxLayout="column" fxLayoutAlign="space-between">
                <mat-form-field>
                    <input matInput placeholder="Nome do streamer" formControlName="name">
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Biografia do streamer" formControlName="bio">
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Foto do streamer" formControlName="photo_avatar">
                </mat-form-field>
                <mat-form-field>
                    <mat-select placeholder="Guilda do streamer" formControlName="guilda">
                        <mat-option *ngFor="let guilda of guildas$ | async" [value]="guilda">{{guilda.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Adicionar plataformas
                        </mat-panel-title>
                    </mat-expansion-panel-header>
              
                    <div fxLayout="row" fxLayoutAlign="space-between stretch">

                        <form [formGroup]="plataformaForm" (ngSubmit)="addPlataforma()" #formPlat="ngForm"
                            fxFlex="40" fxLayout="column" fxLayoutAlign="space-between">
                            <mat-form-field>
                                <mat-select placeholder="Plataforma" formControlName="plataforma" >
                                    <mat-option *ngFor="let plataforma of plataformas$ | async" [value]="plataforma" >{{plataforma.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                            <mat-form-field>
                                <input matInput placeholder="Nome do canal/perfil do streamer" formControlName="name_channel" >
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Id do canal/perfil do streamer" formControlName="id_channel" >
                            </mat-form-field>

                            <div fxLayout="row" fxLayoutAlign="start start">
                                <button mat-button color="primary" type="submit" [disabled]="!plataformaForm.valid">Adicionar</button>
                                <button mat-button color="warn" (click)="cancelPlataforma()" >Limpar</button>
                            </div>
                        </form>
    
                        <div fxFlex="10"></div>
    
                        <div fxFlex="50">
                            <mat-card fxFlex>

                                <mat-list>
                                    <mat-list-item *ngFor="let sp of streamerPlataformas">
                                        <mat-icon mat-list-icon>folder</mat-icon>
                                        <!-- <h5 mat-line>{{feed.created_at | date:'dd/MM' }}</h5> -->
                                        <h4 mat-line>{{sp.plataforma?.name}} - {{sp.name_channel}}</h4>
                        
                                        <button mat-icon-button color="primary" (click)="edit(sp)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button mat-icon-button color="warn" (click)="delete(sp)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                            </mat-card>

                        </div>
                    </div>


              
                </mat-expansion-panel>
            </mat-accordion>

            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-button color="primary" type="submit" [disabled]="!streamerForm.valid">Salvar</button>
                <button mat-button color="warn" type="reset">Cancelar</button>
            </div>
        </form>

        <mat-list>
            <h3 mat-subheader>Streamers</h3>
            <mat-list-item *ngFor="let streamer of streamers$ | async">
                <mat-icon mat-list-icon>folder</mat-icon>
                <!-- <h5 mat-line>{{feed.created_at | date:'dd/MM' }}</h5> -->
                <h4 mat-line>{{streamer.name}}</h4>

                <button mat-icon-button color="primary" (click)="edit(streamer)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="delete(streamer)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>