<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>PORTAL F.F.</span>
    <span class="spacer"></span>
    <span>
      <button mat-button [matMenuTriggerFor]="menu">Gerenciar Notícias</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="feed">Cadastrar Notícias</button>
        <button mat-menu-item routerLink="feed-gnews">Carregar Gnews</button>
        <button mat-menu-item routerLink="category">Cadastrar Categorias</button>
      </mat-menu>
      <button mat-button [matMenuTriggerFor]="menuStreamer">Gerenciar Streamers</button>
      <mat-menu #menuStreamer="matMenu">
        <button mat-menu-item routerLink="streamer">Streamers</button>
        <button mat-menu-item routerLink="plataforma">Plataforma</button>
      </mat-menu>
      <button mat-button routerLink="guilda">Guildas</button>
    </span>
  </mat-toolbar-row>
</mat-toolbar>

<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between stretch">
    <div fxFlex="100">
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-card>