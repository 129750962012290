<mat-card fxFlex>
    <mat-card-title>
        Notícia
    </mat-card-title>
    
    <mat-card-content>
        <form [formGroup]="feedForm" (ngSubmit)="save()" fxLayout="column" fxLayoutAlign="space-between" #form="ngForm">
            <mat-form-field>
                <input matInput placeholder="Título da notícia" formControlName="title">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Resumo da notícia" formControlName="resume">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Imagem do cabeçalho da notícia" formControlName="image_header">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Link da origem da notícia (ex.: http://...)" formControlName="link_font">
            </mat-form-field>
            <mat-form-field>
                <mat-select placeholder="Categoria da notícia" formControlName="category">
                    <mat-option *ngFor="let cat of categorys$ | async" [value]="cat">{{cat.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <ckeditor matInput [editor]="Editor" formControlName="content"></ckeditor>

            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-button color="primary" type="submit" [disabled]="!feedForm.valid">Salvar</button>
                <button mat-button color="warn" type="reset">Cancelar</button>
            </div>
        </form>

        <mat-list>
            <h3 mat-subheader>Notícias</h3>
            <mat-list-item *ngFor="let feed of feeds$ | async">
                <mat-icon mat-list-icon>folder</mat-icon>
                <!-- <h5 mat-line>{{feed.created_at | date:'dd/MM' }}</h5> -->
                <h4 mat-line>{{feed.title}}</h4>

                <button mat-icon-button color="primary" (click)="edit(feed)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="delete(feed)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>