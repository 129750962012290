<mat-card fxFlex>
    <mat-card-title>
        Plataforma
    </mat-card-title>
    
    <mat-card-content>
        <form [formGroup]="plataformaForm" (ngSubmit)="save()" fxLayout="column" fxLayoutAlign="space-between" #form="ngForm">
            <mat-form-field>
                <input matInput placeholder="Nome da plataforma" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Logo da plataforma" formControlName="logo">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Link base dos vídeos" formControlName="link_base_video">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Link base dos canais/perfis" formControlName="link_base_channel">
            </mat-form-field>
            
            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-button color="primary" type="submit" [disabled]="!plataformaForm.valid">Salvar</button>
                <button mat-button color="warn" type="reset">Cancelar</button>
            </div>
        </form>

        <mat-list>
            <h3 mat-subheader>Plataformas</h3>
            <mat-list-item *ngFor="let plataforma of plataformas$ | async">
                <mat-icon mat-list-icon>folder</mat-icon>
                <!-- <h5 mat-line>{{feed.created_at | date:'dd/MM' }}</h5> -->
                <h4 mat-line>{{plataforma.name}}</h4>

                <button mat-icon-button color="primary" (click)="edit(plataforma)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="delete(plataforma)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>