import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Category } from '../model/category';
import { Observable } from 'rxjs';
import { AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private categorysCollection: AngularFirestoreCollection<Category> = this.afs.collection('categorys');

  constructor(private afs: AngularFirestore) { }

  getCategorys(): Observable<Category[]> {
    return this.categorysCollection.valueChanges();
  }

  saveCategory(category) {
    category.id = this.afs.createId();
    return this.categorysCollection.doc(category.id).set(category);
    // return this.categorysCollection.add(category);
  }

  deleteCategory(category) {
    return this.categorysCollection.doc(category.id).delete();
  }

  updateCategory(category) {
    return this.categorysCollection.doc(category.id).update(category);
  }
}
