import { Component, OnInit, ViewChild } from '@angular/core';
import { Plataforma } from 'src/app/model/plataforma';
import { Observable } from 'rxjs';
import { FormGroup, Validators, NgForm, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlataformaService } from 'src/app/services/plataforma.service';

@Component({
  selector: 'app-plataforma',
  templateUrl: './plataforma.component.html',
  styleUrls: ['./plataforma.component.scss']
})
export class PlataformaComponent implements OnInit {

  plataformas$: Observable<Plataforma[]>;

  plataformaForm: FormGroup = this.fb.group({
    id: [undefined],
    name: ['', [Validators.required]],
    logo: [''],
    link_base_video: [''],
    link_base_channel: ['', [Validators.required]],
    key_access: [''],
    last_update: [undefined],
    created_at: [undefined]
  })

  @ViewChild('form') form: NgForm;

  constructor(
    private fb: FormBuilder,
    private plataformaService: PlataformaService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.plataformas$ = this.plataformaService.getPlataformas();
  }

  save() {
    let plataforma: Plataforma = this.plataformaForm.value;
    plataforma.last_update = new Date();
    if (!plataforma.id) {
      plataforma.created_at = new Date();
      this.plataformaService.savePlataforma(plataforma).then(() => {
        this.snackBar.open('Plataforma salva com sucesso', 'OK', {duration: 2000});
        this.form.resetForm();
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    } else {
      this.plataformaService.updatePlataforma(plataforma).then(() => {
        this.snackBar.open('Plataforma atualizada com sucesso', 'OK', {duration: 2000});
        this.form.resetForm();
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    }
  }

  cancel() {
    this.plataformaForm.reset();
  }

  edit(plataforma: Plataforma) {
    if(!plataforma.key_access)
      plataforma.key_access = "";
    this.plataformaForm.setValue(plataforma);
  }

  delete(plataforma: Plataforma) {
    this.plataformaService.deletePlataforma(plataforma).then(() => {
      this.snackBar.open('Plataforma removida com sucesso', 'OK', {duration: 2000});
    }).catch(() => {
      this.snackBar.open('Erro ao remover, tente novamente mais tarde', 'OK', {duration: 3000});
    })
  }

}