import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Category } from 'src/app/model/category';
import { FormBuilder, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  categorys$: Observable<Category[]>;
  categoryForm = this.fb.group({
    id: [undefined],
    name: ['', Validators.required]
  })

  @ViewChild('nameCategory') categoryName: ElementRef;

  constructor(
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.categorys$ = this.categoryService.getCategorys();
  }

  save() {
    let category: Category = this.categoryForm.value;
    if (!category.id) {
      this.categoryService.saveCategory(category).then(() => {
        this.snackBar.open('Categoria salva com sucesso', 'OK', {duration: 2000});
        this.categoryForm.reset();
        this.categoryName.nativeElement.focus();
      }).catch(() => {
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    } else {
      this.categoryService.updateCategory(category).then(() => {
        this.snackBar.open('Categoria atualizada com sucesso', 'OK', {duration: 2000});
        this.categoryForm.reset();
        this.categoryName.nativeElement.focus();
      }).catch(() => {
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    }
  }

  cancel() {
    this.categoryForm.reset();
  }

  edit(cat: Category) {
    this.categoryForm.setValue(cat);
  }

  delete(cat: Category) {
    this.categoryService.deleteCategory(cat).then(() => {
      this.snackBar.open('Categoria removida com sucesso', 'OK', {duration: 2000});
    }).catch(() => {
      this.snackBar.open('Erro ao remover, tente novamente mais tarde', 'OK', {duration: 3000});
    })
  }

}
