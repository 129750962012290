import { Injectable } from '@angular/core';
import { Feed } from '../model/feed';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  private feedsCollection: AngularFirestoreCollection<Feed> = this.afs.collection('feeds');

  constructor(private afs: AngularFirestore, private datePipe: DatePipe, private http: HttpClient) { }

  getFeeds(): Observable<Feed[]> {
    return this.feedsCollection.valueChanges();
  }

  saveFeed(feed) {
    feed.id = this.afs.createId();
    return this.feedsCollection.doc(feed.id).set(feed);
    // return this.categorysCollection.add(category);
  }

  deleteFeed(feed) {
    return this.feedsCollection.doc(feed.id).delete();
  }

  updateFeed(feed) {
    return this.feedsCollection.doc(feed.id).update(feed);
  }

  getGnews() {
    let url = "https://gnews.io/api/v3/search?";
    let minDataNow = "&mindate="+this.datePipe.transform(new Date, 'yyyy-MM-dd');
    let key = "&token=0b0f58d6d83588ef5abe17f981a84791";
    let lang = "&lang=pt-BR";
    let query = "q=free fire";

    return this.http.get(url+query+lang+minDataNow+key);

    // console.log(dataNow.getDate())
    // console.log(dataNow.getFullYear())
    // console.log(dataNow.getMonth()+1)
    // console.log(dataNow.get)
  }
}
