import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Feed } from 'src/app/model/feed';
import { Observable } from 'rxjs';
import { FeedService } from 'src/app/services/feed.service';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/model/category';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {

  feeds$: Observable<Feed[]>;
  categorys$: Observable<Category[]>;

  feedForm: FormGroup = this.fb.group({
    id: [undefined],
    title: ['', [Validators.required, Validators.minLength(5)]],
    image_header: ['', [Validators.required]],
    category: [undefined, [Validators.required]],
    content: ['', [Validators.required, Validators.minLength(10)]],
    resume: ['', [Validators.required, Validators.minLength(10)]],
    link_font: [''],
    last_update: [undefined],
    created_at: [undefined]
  })
  public Editor = ClassicEditor;

  @ViewChild('form') form: NgForm;

  constructor(
    private fb: FormBuilder,
    private feedService: FeedService,
    private categoryService: CategoryService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.feeds$ = this.feedService.getFeeds();
    this.categorys$ = this.categoryService.getCategorys();
  }

  save() {
    let feed: Feed = this.feedForm.value;
    feed.last_update = new Date();
    if (!feed.id) {
      feed.created_at = new Date();
      this.feedService.saveFeed(feed).then(() => {
        this.snackBar.open('Notícia salva com sucesso', 'OK', {duration: 2000});
        // this.feedForm.reset();
        this.form.resetForm();
        // this.categoryName.nativeElement.focus();
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    } else {
      this.feedService.updateFeed(feed).then(() => {
        this.snackBar.open('Notícia atualizada com sucesso', 'OK', {duration: 2000});
        // this.feedForm.reset();
        this.form.resetForm();
        // this.categoryName.nativeElement.focus();
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    }
  }

  cancel() {
    this.feedForm.reset();
  }

  edit(feed: Feed) {
    this.feedForm.setValue(feed);
  }

  delete(feed: Feed) {
    this.feedService.deleteFeed(feed).then(() => {
      this.snackBar.open('Notícia removida com sucesso', 'OK', {duration: 2000});
    }).catch(() => {
      this.snackBar.open('Erro ao remover, tente novamente mais tarde', 'OK', {duration: 3000});
    })
  }

}