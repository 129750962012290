import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedComponent } from './pages/feed/feed.component';
import { CategoryComponent } from './pages/category/category.component';
import { StreamerComponent } from './pages/streamer/streamer.component';
import { GuildaComponent } from './pages/guilda/guilda.component';
import { PlataformaComponent } from './pages/plataforma/plataforma.component';
import { FeedGnewsComponent } from './pages/feed-gnews/feed-gnews.component';


const routes: Routes = [
  { path: 'feed', component: FeedComponent },
  { path: 'feed-gnews', component: FeedGnewsComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'streamer', component: StreamerComponent },
  { path: 'guilda', component: GuildaComponent },
  { path: 'plataforma', component: PlataformaComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
