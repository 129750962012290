<mat-card fxFlex>
    <mat-card-title>
        Guilda
    </mat-card-title>

    <mat-card-content>
        <form [formGroup]="guildaForm" (ngSubmit)="save()" fxLayout="column" fxLayoutAlign="space-between" #form="ngForm">
            <mat-form-field>
                <input matInput placeholder="Nome da guilda" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Descrição da guilda" formControlName="description">
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Logo da guilda" formControlName="photo_avatar">
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-button color="primary" type="submit" [disabled]="!guildaForm.valid">Salvar</button>
                <button mat-button color="warn" type="reset">Cancelar</button>
            </div>
        </form>

        <mat-list>
            <h3 mat-subheader>Guildas</h3>
            <mat-list-item *ngFor="let guilda of guildas$ | async">
                <mat-icon mat-list-icon>folder</mat-icon>
                <!-- <h5 mat-line>{{feed.created_at | date:'dd/MM' }}</h5> -->
                <h4 mat-line>{{guilda.name}}</h4>

                <button mat-icon-button color="primary" (click)="edit(guilda)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="delete(guilda)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>