<mat-card fxFlex>
    <mat-card-title>
        Categoria
    </mat-card-title>
    
    <mat-card-content>
        <form [formGroup]="categoryForm" (ngSubmit)="save()" fxLayout="column" fxLayoutAlign="space-between">
            <mat-form-field fxFlex>
                <input matInput placeholder="Nome da categoria" formControlName="name" #nameCategory> 
                <!-- <mat-hint>Nome da categoria</mat-hint> -->
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-button color="primary" type="submit" [disabled]="!categoryForm.valid">Salvar</button>
                <button mat-button color="warn" type="reset">Cancelar</button>
            </div>
        </form>

        <mat-list>
            <h3 mat-subheader>Categorias</h3>
            <mat-list-item *ngFor="let cat of categorys$ | async">
                <mat-icon mat-list-icon>folder</mat-icon>
                <h4 mat-line>{{cat.name}}</h4>
                <button mat-icon-button color="primary" (click)="edit(cat)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="delete(cat)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>