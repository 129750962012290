<mat-card fxFlex>
    <mat-card-title>
        Notícias do Gnews
    </mat-card-title>
    
    <mat-card-content>
        <table mat-table [dataSource]="listGnews" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="date_publish">
                <th mat-header-cell *matHeaderCellDef> Publicação </th>
                <td mat-cell *matCellDef="let element"> {{element.publishedAt | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Título </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Site </th>
                <td mat-cell *matCellDef="let element"> {{element.source.name}} </td>
            </ng-container>

            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Categoria </th>
                <td mat-cell *matCellDef="let element"> 
                    <mat-select placeholder="Categoria" (selectionChange)="setCategory(element, $event)">
                        <mat-option *ngFor="let cat of categorys" [value]="cat" >{{cat.name}}</mat-option>
                    </mat-select>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <br>
        <button mat-raised-button (click)="enviar()"> Enviar </button>
    </mat-card-content>
</mat-card>