import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Guilda } from 'src/app/model/guilda';
import { FormGroup, Validators, NgForm, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GuildaService } from 'src/app/services/guilda.service';

@Component({
  selector: 'app-guilda',
  templateUrl: './guilda.component.html',
  styleUrls: ['./guilda.component.scss']
})
export class GuildaComponent implements OnInit {

  guildas$: Observable<Guilda[]>;

  guildaForm: FormGroup = this.fb.group({
    id: [undefined],
    name: ['', [Validators.required]],
    photo_avatar: [''],
    description: [''],
    created_at: [undefined],
    last_update: [undefined]
  })

  @ViewChild('form') form: NgForm;

  constructor(
    private fb: FormBuilder,
    private guildaService: GuildaService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.guildas$ = this.guildaService.getGuildas();
  }

  save() {
    let guilda: Guilda = this.guildaForm.value;
    guilda.last_update = new Date();
    if (!guilda.id) {
      guilda.created_at = new Date();
      this.guildaService.save(guilda).then(() => {
        this.snackBar.open('Guilda salva com sucesso', 'OK', {duration: 2000});
        // this.feedForm.reset();
        this.form.resetForm();
        // this.categoryName.nativeElement.focus();
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    } else {
      this.guildaService.update(guilda).then(() => {
        this.snackBar.open('Guilda atualizada com sucesso', 'OK', {duration: 2000});
        // this.feedForm.reset();
        this.form.resetForm();
        // this.categoryName.nativeElement.focus();
      }).catch((e) => {
        console.log(e)
        this.snackBar.open('Erro inesperado ao salvar, por favor tente mais tarde!', 'OK', {duration: 3000});
      })
    }
  }

  cancel() {
    this.guildaForm.reset();
  }

  edit(guilda: Guilda) {
    this.guildaForm.setValue(guilda);
  }

  delete(guilda: Guilda) {
    this.guildaService.delete(guilda).then(() => {
      this.snackBar.open('Guilda removida com sucesso', 'OK', {duration: 2000});
    }).catch(() => {
      this.snackBar.open('Erro ao remover, tente novamente mais tarde', 'OK', {duration: 3000});
    })
  }

}
